// Shopify Predictive Search

import PredictiveSearch from "@shopify/theme-predictive-search";

var predictiveSearch = new PredictiveSearch({
  search_path: PredictiveSearch.SEARCH_PATH,
  resources: {
    type: [PredictiveSearch.TYPES.PRODUCT],
    limit: 3,
    options: {
      unavailable_products: "last",
      fields: [
        PredictiveSearch.FIELDS.TITLE,
        PredictiveSearch.FIELDS.PRODUCT_TYPE,
        PredictiveSearch.FIELDS.VARIANTS_TITLE,
        PredictiveSearch.FIELDS.VARIANTS_SKU,
      ]
    }
  }
});

predictiveSearch.on("success", function(json) {
  // See "Success Response" section of this document
    
    const productSuggestions = json.resources.results.products;
    
    let product_results = ''

    document.querySelector('.js-search-box').style.display = 'block'

    if (productSuggestions.length > 0) {

        for (const suggestion of productSuggestions) {

            // console.log(suggestion)

            let url = window.Shopify.routes.root + suggestion.url.slice(1);

            product_results += `
                <a class='block w-full' href='${url}'>
                    <div class='block mb-4'>
                        <img src='${suggestion.featured_image.url}' />
                    </div>
                    <div class='font-bold text-13px'>
                        ${suggestion.title}
                    </div>
                </a>
            `
        }

    } else {
        product_results = "<div class='text-center col-span-3 p-10'>No products found!</div>"
    }

    document.querySelector('.js-search-results').innerHTML = product_results
    
    
});

predictiveSearch.on("error", function(error) {
  // See "HTTP status `3xx-4xx` Response" section of this document
    
  console.log(error)
});


const search_triggers = document.querySelectorAll('.js-search')
const search_bar_container = document.querySelector('.js-search-bar-container')
const search_bar = document.querySelector('.js-search-bar')
    

for (const search_trigger of search_triggers) {
  search_trigger.addEventListener('click', (e) => {
    e.preventDefault();
    search_bar_container.classList.toggle('none')
  })
}

search_bar.addEventListener('keyup', (e) => {
    predictiveSearch.query(search_bar.value);
})

search_bar.addEventListener('blur', () => {
    setTimeout(() => {
        document.querySelector('.js-search-box').style.display = 'none'
    }, 1000)
})